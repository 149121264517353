import React, { useMemo } from 'react';
import { Table } from 'legacy-components/ui/table/table';
import { ApplicationsActionsCell, ApplicationsTableRow } from './common/types/applications-table-row';
import { getDate } from 'helpers/date';
import { OffersItemDto } from 'common/types/types';
import { useNavigate } from 'react-router-dom';
import { ApplicationsTableAccessor } from './common/enums/applications-table-accessor';
import { OffersTenantCell } from './components/offers-tenant-cell';
import { OffersIncomeCell } from './components/offers-income-cell';
import { OffersAppliedCell } from './components/offers-applied-cell';
import { OffersStatusCell } from './components/offers-status-cell';
import {
  createEnumDelimitedArrayParam,
  createEnumParam,
  NumberParam,
  QueryParamConfig,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { TableColumn } from 'common/types/components/ui/table/table-props.type';
import { OfferedCell } from './components/offered-cell';
import { SortOrder } from 'common/types/sort-order';
import { OfferStatusDto } from 'common/enums/enums';
import SortOrderEnumParam from 'query-params/sortOrderEnumParam';
import { OfferTitle } from 'legacy-pages/renter/profile/renter-profile/offers/components/OfferTitle/OfferTitle';
import { useAcceptOffer, useRejectOffer, useAdminOffers } from 'hooks/query';
import { OffersFilters } from 'components/organisms/Filters/OffersFilters';

type LandlordOffersQueryParamConfig = {
  sortBy: QueryParamConfig<'date'>;
  order: QueryParamConfig<SortOrder>;
  statuses: QueryParamConfig<`${OfferStatusDto}`[]>;
  proposedRentMin: QueryParamConfig<number, number | null | undefined>;
  proposedRentMax: QueryParamConfig<number, number | null | undefined>;
  page: QueryParamConfig<number>;
};

const AdminOffers = () => {
  const queryParamConfig = useMemo<LandlordOffersQueryParamConfig>(
    () => ({
      sortBy: withDefault(createEnumParam(['date']), 'date'),
      order: withDefault(SortOrderEnumParam, 'desc'),
      statuses: withDefault(
        createEnumDelimitedArrayParam(Object.values(OfferStatusDto) as `${OfferStatusDto}`[], ','),
        [],
      ),
      proposedRentMin: NumberParam,
      proposedRentMax: NumberParam,
      page: withDefault(NumberParam, 1),
    }),
    [],
  );
  const [query, setQuery] = useQueryParams(queryParamConfig);
  const navigate = useNavigate();
  const {
    data: adminOffers,
    isLoading: isAdminOffersLoading,
    isFetching: isAdminOffersFetching,
  } = useAdminOffers(
    {
      sorters: { appliedAt: query.order },
      filters: {
        status: query.statuses,
        proposedRent: { min: query.proposedRentMin || undefined, max: query.proposedRentMax || undefined },
      },
      page: query.page,
      perPage: 5,
    },
    {
      placeholderData: (previousValue) => previousValue,
    },
  );
  const { mutate: acceptOffer, isPending: isAcceptingPending } = useAcceptOffer();
  const { mutate: rejectOffer, isPending: isRejectionPending } = useRejectOffer();

  const handleAccept = React.useCallback(async (item: OffersItemDto) => {
    acceptOffer(item.id);
  }, []);

  const handleReject = React.useCallback(async (item: OffersItemDto) => {
    // TODO: if admin will have ability to delete, need to add functionality
    // rejectOffer(item.id);
  }, []);

  const getActions = React.useCallback(
    (item: OffersItemDto): ApplicationsActionsCell => {
      return {
        onViewFullOffer: () => navigate(`${item.id}`),
        onAccept: () => handleAccept(item),
        onReject: () => handleReject(item),
        onCounter: () => {},
      };
    },
    [handleAccept, handleReject, navigate],
  );

  const formattedTableData = React.useMemo<ApplicationsTableRow[]>(() => {
    return (
      adminOffers?.items.map((item) => ({
        tenant: {
          id: item.id,
          userName: item.renterName,
          img: item.renterThumbnailUrl ?? null, //: TODO: need to add to response
          country: '', //: TODO: need to add to response
          region: '', //: TODO: need to add to response
        },
        income: {
          price: item.price,
          income: Boolean(item.price)
            ? Number((item.proposedRent / item.price).toFixed(1))
            : Number(item.proposedRent.toFixed(1)),
        },
        applied: getDate(item.appliedAt),
        creditScore: 750,
        offered: {
          offered: item.proposedRent,
        },
        payload: {
          id: item.id,
          actions: getActions(item),
          status: item.status,
        },
        status: {
          status: item.status,
        },
      })) || []
    );
  }, [adminOffers, getActions]);

  const columns: TableColumn<ApplicationsTableRow>[] = [
    {
      Header: 'Tenant',
      accessor: ApplicationsTableAccessor.Tenant,
      Cell: OffersTenantCell,
      width: 200,
      minWidth: 50,
    },
    {
      Header: 'Income',
      accessor: ApplicationsTableAccessor.Income,
      Cell: OffersIncomeCell,
      width: 180,
      minWidth: 50,
    },
    {
      Header: 'Offered',
      accessor: ApplicationsTableAccessor.Offered,
      Cell: OfferedCell,
      width: 150,
      minWidth: 50,
    },
    {
      Header: 'Applied',
      accessor: ApplicationsTableAccessor.Applied,
      Cell: OffersAppliedCell,
      width: 150,
      minWidth: 50,
      sorter: true,
      sortOrder: query.order,
      onSort: (sortOrder) => setQuery((prev) => ({ ...prev, page: 1, order: sortOrder })),
    },
    //TODO : COMING SOON
    // {
    //   Header: 'Credit Score',
    //   accessor: ApplicationsTableAccessor.CreditScore,
    //   Cell: OffersCreditStoreCell,
    //   width: 200,
    //   minWidth: 50,
    // },
    {
      Header: 'Status',
      accessor: ApplicationsTableAccessor.Status,
      Cell: OffersStatusCell,
      width: 100,
      minWidth: 50,
    },
    // {
    //   accessor: ApplicationsTableAccessor.Payload,
    //   Cell: OffersActionsMenuCell,
    //   width: 50,
    //   minWidth: 50,
    // },
  ];

  const paginationCallback = React.useCallback((newPage: number) => {
    setQuery((prev) => ({ ...prev, page: newPage }));
  }, []);

  const paginationTableProps = useMemo(() => {
    return {
      currentPage: query.page,
      totalCount: adminOffers?.totalCount ?? 0,
      pageSize: 5,
      onPageChange: paginationCallback,
    };
  }, [query.page, adminOffers?.totalCount, paginationCallback]);

  return (
    <div className='w-full overflow-x-auto shadow-white-xl px-4 py-4 md:px-[34px] md:py-[28px]'>
      <div className='flex justify-between items-center mb-2 md:mb-9'>
        <OfferTitle title={'Applications'} />
        <OffersFilters />
      </div>
      <Table
        data={formattedTableData}
        paginationProps={paginationTableProps}
        columns={columns}
        isLoading={isAdminOffersLoading || isAdminOffersFetching || isAcceptingPending || isRejectionPending}
      />
    </div>
  );
};

export { AdminOffers };
