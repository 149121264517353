import React, { useMemo, useState } from 'react';
import { Table } from 'legacy-components/ui/table/table';
import { ApplicationsActionsCell, ApplicationsTableRow } from './common/types/applications-table-row';
import { getDate } from 'helpers/date';
import { OffersItemDto } from 'common/types/types';
import { useNavigate } from 'react-router-dom';
import { ApplicationsTableAccessor } from './common/enums/applications-table-accessor';
import { OffersTenantCell } from './components/offers-tenant-cell';
import { OffersAppliedCell } from './components/offers-applied-cell';
import { OffersStatusCell } from './components/offers-status-cell';
import { OffersActionsMenuCell } from './components/offers-actions-menu-cell';
import {
  createEnumDelimitedArrayParam,
  createEnumParam,
  NumberParam,
  QueryParamConfig,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import SortOrderEnumParam from 'query-params/sortOrderEnumParam';
import { TableColumn } from 'common/types/components/ui/table/table-props.type';
import { SortOrder } from 'common/types/sort-order';
import { OfferStatusDto } from 'common/enums/services/api/offers/offer-status-dto.enum';
import { OfferedCell } from './components/offered-cell';
import { OffersLocationCell } from './components/offers-location-cell';
import { OffersUnitNumberCell } from './components/offers-unit-number-cell';
import { OfferTitle } from 'legacy-pages/renter/profile/renter-profile/offers/components/OfferTitle/OfferTitle';
import { useOffers, useAcceptOffer, useRejectOffer } from 'hooks/query';
import { openNotificationModal } from 'store/use-notification-modal-store';
import { NotificationType } from 'common/enums/enums';
import { OffersFilters } from 'components/organisms/Filters/OffersFilters';
import { RejectOfferModalByLandlordModal } from 'components/organisms/RejectOfferModalByLandlord/RejectOfferModalByLandlord';
import { useUnverifiedProfileActionGuard } from 'hooks/use-unverified-profile-guard';
import useRequestBackgroundCheck from 'hooks/query/offers/use-request-background-check';

type LandlordOffersQueryParamConfig = {
  sortBy: QueryParamConfig<'date'>;
  order: QueryParamConfig<SortOrder>;
  statuses: QueryParamConfig<`${OfferStatusDto}`[]>;
  proposedRentMin: QueryParamConfig<number, number | null | undefined>;
  proposedRentMax: QueryParamConfig<number, number | null | undefined>;
  page: QueryParamConfig<number>;
};

const Offers = () => {
  const [selectedOfferId, setSelectedOfferId] = useState('');
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const queryParamConfig = useMemo<LandlordOffersQueryParamConfig>(
    () => ({
      sortBy: withDefault(createEnumParam(['date']), 'date'),
      order: withDefault(SortOrderEnumParam, 'desc'),
      statuses: withDefault(
        createEnumDelimitedArrayParam(Object.values(OfferStatusDto) as `${OfferStatusDto}`[], ','),
        [],
      ),
      proposedRentMin: NumberParam,
      proposedRentMax: NumberParam,
      page: withDefault(NumberParam, 1),
    }),
    [],
  );
  const [query, setQuery] = useQueryParams(queryParamConfig);
  const navigate = useNavigate();
  const {
    data: offers,
    isLoading: isOffersLoading,
    isFetching: isOffersFetching,
  } = useOffers(
    {
      sorters: { appliedAt: query.order },
      filters: {
        status: query.statuses,
        proposedRent: { min: query.proposedRentMin || undefined, max: query.proposedRentMax || undefined },
      },
      page: query.page,
      perPage: 5,
    },
    {
      placeholderData: (previousValue) => previousValue,
    },
  );
  const { withActionGuard } = useUnverifiedProfileActionGuard({
    modalProps: {
      actionText: 'accept or reject an offer',
    },
  });

  const { mutate: acceptOffer, isPending: isAcceptingPending } = useAcceptOffer({
    onError: (e) => {
      openNotificationModal({
        type: NotificationType.ERROR,
        title: e?.response?.data?.detail ?? 'New offers cannot be accepted, try again later.',
      });
    },
  });
  const { mutate: rejectOffer, isPending: isRejectionPending } = useRejectOffer({
    onError: (e) => {
      openNotificationModal({
        type: NotificationType.ERROR,
        title: e?.response?.data?.detail ?? 'New offers cannot be rejected, try again later.',
      });
    },
  });
  const { mutate: requestBackgroundCheck, isPending: isRequestBackgroundCheckPending } = useRequestBackgroundCheck({
    onError: (e) => {
      openNotificationModal({
        type: NotificationType.ERROR,
        title: e?.response?.data?.detail ?? 'Background check cannot be requested, try again later.',
      });
    },
  });

  const handleAccept = React.useCallback((item: OffersItemDto) => {
    withActionGuard(() => acceptOffer(item.id));
  }, [withActionGuard]);

  const handleReject = React.useCallback((item: OffersItemDto) => {
    withActionGuard(() => {
      setSelectedOfferId(item?.id);
      setOpenRejectModal(true);
    });
  }, [withActionGuard]);

  const handleRequestBackgroundCheck = React.useCallback((item: OffersItemDto) => {
    withActionGuard(() => requestBackgroundCheck(item.id));
  }, [withActionGuard]);

  const getActions = React.useCallback(
    (item: OffersItemDto): ApplicationsActionsCell => {
      return {
        onViewFullOffer: () => navigate(`${item.id}`),
        onAccept: () => handleAccept(item),
        onRequestBackgroundCheck: () => handleRequestBackgroundCheck(item),
        onReject: () => handleReject(item),
        onCounter: () => {},
      };
    },
    [handleAccept, handleReject, navigate],
  );

  const formattedTableData = React.useMemo<ApplicationsTableRow[]>(() => {
    return (
      offers?.items.map((item) => ({
        tenant: {
          id: item.id,
          userName: item.renterName,
          img: item.renterThumbnailUrl ?? null, //: TODO: need to add to response
          country: '', //: TODO: need to add to response
          region: '', //: TODO: need to add to response
        },
        applied: getDate(item.appliedAt),
        creditScore: 750,
        offered: {
          offered: item.proposedRent,
        },
        location: { location: item.location },
        unitNumber: { unitNumber: item.unitNumber },
        payload: {
          id: item.id,
          actions: getActions(item),
          status: item.status,
        },
        status: {
          status: item.status,
        },
      })) || []
    );
  }, [offers, getActions]);

  const columns: TableColumn<ApplicationsTableRow>[] = [
    {
      Header: 'Applied',
      accessor: ApplicationsTableAccessor.Applied,
      Cell: OffersAppliedCell,
      width: 150,
      minWidth: 50,
      sorter: true,
      sortOrder: query.order,
      onSort: (sortOrder) => setQuery((prev) => ({ ...prev, page: 1, order: sortOrder })),
    },
    {
      Header: 'Tenant',
      accessor: ApplicationsTableAccessor.Tenant,
      Cell: OffersTenantCell,
      minWidth: 180,
    },
    {
      Header: 'Location',
      accessor: ApplicationsTableAccessor.Location,
      Cell: OffersLocationCell,
      width: 200,
      minWidth: 300,
    },
    {
      Header: 'Unit number',
      accessor: ApplicationsTableAccessor.UnitNumber,
      Cell: OffersUnitNumberCell,
      width: 120,
      minWidth: 50,
    },
    {
      Header: 'Offered',
      accessor: ApplicationsTableAccessor.Offered,
      Cell: OfferedCell,
      width: 150,
      minWidth: 100,
    },
    //TODO : COMING SOON
    // {
    //   Header: 'Credit Score',
    //   accessor: ApplicationsTableAccessor.CreditScore,
    //   Cell: OffersCreditStoreCell,
    //   width: 200,
    //   minWidth: 50,
    // },
    {
      Header: 'Status',
      accessor: ApplicationsTableAccessor.Status,
      Cell: OffersStatusCell,
      width: 120,
    },
    {
      Header: 'Actions',
      accessor: ApplicationsTableAccessor.Payload,
      Cell: OffersActionsMenuCell,
      width: 100,
    },
  ];

  const paginationCallback = React.useCallback((newPage: number) => {
    setQuery((prev) => ({ ...prev, page: newPage }));
  }, []);

  const paginationTableProps = useMemo(() => {
    return {
      currentPage: query.page,
      totalCount: offers?.totalCount ?? 0,
      pageSize: 5,
      onPageChange: paginationCallback,
    };
  }, [query.page, offers?.totalCount, paginationCallback]);

  return (
    <div className='w-full overflow-x-auto shadow-white-xl px-4 py-4 md:px-[34px] md:py-[28px]'>
      <div className='flex justify-between items-center mb-2 md:mb-9'>
        <OfferTitle title={'Applications'} />
        <OffersFilters />
      </div>
      <Table
        data={formattedTableData}
        paginationProps={paginationTableProps}
        columns={columns}
        isLoading={isOffersLoading || isOffersFetching || isAcceptingPending || isRequestBackgroundCheckPending || isRejectionPending}
      />
      <RejectOfferModalByLandlordModal
        isOpen={openRejectModal}
        isSubmitting={isRejectionPending}
        onClose={() => setOpenRejectModal(false)}
        onSubmit={rejectOffer}
        offerId={selectedOfferId}
      />
    </div>
  );
};

export { Offers };
