import { AuthRole, OfferStatusDto } from "common/enums/enums";
import { getLandLordOfferStatuses, getRenterOfferStatuses } from "common/enums/services/api/offers/offer-status-dto.enum";
import { UserDto } from "common/types/types";

export function getUserOfferStatuses(user: UserDto | undefined): Partial<Record<OfferStatusDto, string>> {
  if (user?.roles?.includes(AuthRole.Landlord)) {
    return getLandLordOfferStatuses();
  } else if (user?.roles?.includes(AuthRole.Renter)) {
    return getRenterOfferStatuses();
  }

  return getRenterOfferStatuses();
}