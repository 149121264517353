import { FC } from 'react';
import { OfferStatusProps } from './OfferStatus.types';
import { commonStatusStyle, styles, textStyle } from './OfferStatus.styles';
import clsx from 'clsx';
import { useAuth } from 'hooks';
import { getUserOfferStatuses } from 'helpers/get-user-offer-statuses';

export const OfferStatus: FC<OfferStatusProps> = ({ offerStatus, size }) => {
  const { user } = useAuth();
  const offerStatuses = getUserOfferStatuses(user);
  const statusStyle = styles[offerStatus];
  const textStatusStyle = size ? textStyle(size) : 'text-xs md:text-sm lg:text-[15px]';
  const combinedStyle = clsx(statusStyle, commonStatusStyle, textStatusStyle);
  return (
    <div className={combinedStyle}>
      <span>{`${offerStatuses[offerStatus]}`}</span>
    </div>
  );
};
