import { useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Loader } from 'legacy-components/componets';
import { navigation, notification } from 'services/services';
import { PaymentCardsListForm } from 'legacy-components/payment-cards-list/form';
import { usePaymentCards } from 'hooks/query';

export type CheckoutFormProps = {
  returnUrl?: string;
  isStripeLoading: boolean;
  isFormDisabled?: boolean;
  onCloseModal?: any;
  callback?: any;
  onExecutingCallback?: any;
  setPaymentDone?: (value: boolean) => void;
  setIsStripLoading: (isLoading: boolean) => void;
  clientSecret?: string;
  successMessage?: string;
  paidSum?: number;
};

const CheckoutForm = ({
  returnUrl,
  onCloseModal,
  setPaymentDone,
  isFormDisabled,
  isStripeLoading,
  setIsStripLoading,
  callback,
  onExecutingCallback,
  clientSecret,
  successMessage,
  paidSum,
}: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const { data: paymentCards } = usePaymentCards();
  const paySum = paidSum && paidSum > 0 ? `$${paidSum} USD` : '';

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isUseSavedMethods, setUseSavedMethods] = useState<boolean>(false);
  const [choosedPaymentMethod, setChoosedPaymentMethod] = useState('');

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements || isFormDisabled) {
      return;
    }

    setSubmitLoading(true);
    if (onExecutingCallback) {
      onExecutingCallback();
    }
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl ?? '',
      },
      // @ts-ignore
      redirect: returnUrl ? 'always' : 'if_required',
    });

    if (error) {
      setErrorMessage(error.message || '');
    } else {
      if (callback) {
        callback();
      }

      if (!returnUrl) {
        if (setPaymentDone) {
          notification.success(successMessage || 'Congratulations! Payment was successful.');
          setPaymentDone(true);
        }

        onCloseModal?.();
      }
    }
    setSubmitLoading(false);
  };

  const handleUseSavedMethods = async () => {
    if (!stripe || !elements || !clientSecret || !choosedPaymentMethod || isFormDisabled) {
      return;
    }

    setSubmitLoading(true);
    const { error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: choosedPaymentMethod,
    });

    if (error) {
      setErrorMessage(error.message || '');
    } else {
      if (callback) {
        callback();
      }

      if (setPaymentDone) {
        notification.success(successMessage || 'Congratulations! Payment was successful.');
        setPaymentDone(true);
      }

      if (returnUrl) {
        navigation.push(returnUrl);
      }

      onCloseModal?.();
    }
    setSubmitLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className='max-w-[292px]'>
      {errorMessage && <div style={{color: '#df1b41'}} className='mb-3'> 
        {errorMessage}
      </div>}
      <>
        {isUseSavedMethods ? (
          <>
            <PaymentCardsListForm
              onChange={(e) => {
                setChoosedPaymentMethod(e.target.value);
              }}
              list={paymentCards}
              getCheckedValue={(card) => card.id === choosedPaymentMethod}
            />
            <Button
              label={`Pay ${paySum}`}
              stylesConfig={{ width: '100%', marginTop: 36 }}
              theme={'primary'}
              isLoading={submitLoading}
              disabled={!stripe || !choosedPaymentMethod || isFormDisabled}
              onClick={() => {
                handleUseSavedMethods();
              }}
            />
            <Button
              label={'Back'}
              stylesConfig={{ width: '100%', marginTop: 16 }}
              theme={'secondary'}
              onClick={() => {
                setChoosedPaymentMethod('');
                setUseSavedMethods(false);
              }}
            />
          </>
        ) : (
          <>
            <PaymentElement
              onReady={() => {
                setIsStripLoading(false);
              }}
              className={`min-h-[300px] ${isStripeLoading ? 'hidden' : 'block'}`}
            />
            {!isStripeLoading ? (
              <>
                <Button
                  label={`Pay ${paySum}`}
                  stylesConfig={{ width: '100%', marginTop: 36 }}
                  theme={'primary'}
                  onClick={() => {}}
                  type='submit'
                  disabled={!stripe || isFormDisabled}
                  isLoading={submitLoading}
                />
                {!paymentCards || !paymentCards?.length ? null : (
                  <Button
                    label={'Use saved payment methods'}
                    stylesConfig={{ width: '100%', marginTop: 16 }}
                    theme={'secondary'}
                    disabled={!stripe}
                    onClick={() => {
                      setUseSavedMethods(true);
                    }}
                  />
                )}
              </>
            ) : (
              <Loader />
            )}
          </>
        )}
      </>
    </form>
  );
};

export { CheckoutForm };
