import { InputNumber, Select } from 'antd';
import { FiltersMenuFormProps } from 'components/molecules/Filters/Filters';
import { FC } from 'react';
import { OfferStatusDto } from 'common/enums/services/api/offers/offer-status-dto.enum';
import { useAuth } from 'hooks';
import { getUserOfferStatuses } from 'helpers/get-user-offer-statuses';

const OffersFiltersMenuForm: FC<FiltersMenuFormProps> = ({ values, setFieldValue, id }) => {
  const { proposedRentMin, proposedRentMax, statuses } = values;

  const getSelectPopupContainer = (): HTMLElement => document.getElementById(id) as HTMLElement;

  const { user } = useAuth();
  const offerStatuses = getUserOfferStatuses(user);
  
  const handleMaxPriceBlur = () => {
    const minPriceValue = parseFloat(proposedRentMin);
    const maxPriceValue = parseFloat(proposedRentMax);

    if (maxPriceValue < minPriceValue) {
      setFieldValue('proposedRentMax', minPriceValue);
    }
  };

  return (
    <div className='flex flex-col gap-3 flex-1 w-full'>
      <div className='flex flex-col w-full'>
        <label className={'block mb-2 text-base font-semibold text-black'}>{'Status'}</label>
        <Select
          style={{
            maxWidth: '375px',
          }}
          allowClear
          mode='multiple'
          placeholder='Select statuses'
          getPopupContainer={getSelectPopupContainer}
          size='large'
          value={statuses || null}
          onChange={(value: string) => {
            setFieldValue('statuses', value);
          }}
          options={Object.values(OfferStatusDto).map((v) => ({ value: v, label: offerStatuses[v] }))}
        />
      </div>
      <div className='flex items-center gap-3 w-full'>
        <div className='flex flex-col w-full'>
          <label className={'block mb-2 text-base font-semibold text-black'}>{'Offer price range'}</label>
          <InputNumber
            value={proposedRentMin}
            name='proposedRentMin'
            placeholder='$ Min'
            className='w-full'
            step={1000}
            min={'0'}
            max={'100_000_000_000'}
            formatter={(value) => (value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '')}
            parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setFieldValue('proposedRentMin', value)}
            onBlur={handleMaxPriceBlur}
          />
        </div>
        <div className='flex flex-col w-full'>
          <InputNumber
            value={proposedRentMax}
            name='proposedRentMax'
            step={1000}
            min={'0'}
            max={'100_000_000_000'}
            placeholder='$ Max'
            className='w-full mt-8'
            formatter={(value) => (value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '')}
            parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setFieldValue('proposedRentMax', value)}
            onBlur={handleMaxPriceBlur}
          />
        </div>
      </div>
    </div>
  );
};

export { OffersFiltersMenuForm };
