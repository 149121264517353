import { OffersApiPath } from 'common/enums/enums';
import { CreateOfferRequestDto, OffersDto, OffersItemDto, OffersRequestDto } from 'common/types/types';
import { OfferItemDto } from 'common/types/services/api/offers/offer-item-dto.type';
import { OfferPaymentIntentDto } from 'common/types/services/api/offers/offer-payment-intent-dto.type';
import qs from 'qs';
import { axiosInstance } from './axiosInstance';
import { OfferFileDeleteRequest, OfferFileDto } from 'common/types/services/api/offers/offer-file-dto.type';
import { buildOfferPendingInProgressPaymentUrl } from 'common/enums/services/api/paths/offers-api-path.enum';
import { UserEstateRentApplication } from 'common/generated/types/user-estate-rent-application';
import { UpdateOfferRequestDto } from 'common/types/services/api/offers/offer-create-request-dto.type';

export const getAdminOffers = (query: OffersRequestDto): Promise<OffersDto> => {
  const queryString = qs.stringify(
    {
      filters: {
        proposedRent: [
          query?.filters?.proposedRent?.min ? `>=${query?.filters?.proposedRent?.min}` : undefined,
          query?.filters?.proposedRent?.max ? `<=${query?.filters?.proposedRent?.max}` : undefined,
        ],
        status: query.filters?.status ? query.filters.status.map((s) => `OR%${s}`) : undefined,
      },
      page: query.page,
      perPage: query.perPage,
      sorters: query.sorters,
    },
    { encode: false, arrayFormat: 'repeat' },
  );
  return axiosInstance.get(`${OffersApiPath.GET_ADMIN_OFFERS}?${queryString}`);
};

export const getOffers = (query: OffersRequestDto): Promise<OffersDto> => {
  const queryString = qs.stringify(
    {
      filters: {
        proposedRent: [
          query?.filters?.proposedRent?.min ? `>=${query?.filters?.proposedRent?.min}` : undefined,
          query?.filters?.proposedRent?.max ? `<=${query?.filters?.proposedRent?.max}` : undefined,
        ],
        status: query.filters?.status ? query.filters.status.map((s) => `OR%${s}`) : undefined,
      },
      page: query.page,
      perPage: query.perPage,
      sorters: query.sorters,
    },
    { encode: false, arrayFormat: 'repeat' },
  );
  return axiosInstance.get(`${OffersApiPath.GET_OFFERS}?${queryString}`);
};

export const getRenterOffers = (query: OffersRequestDto): Promise<OffersDto> => {
  const queryString = qs.stringify(
    {
      filters: {
        proposedRent: [
          query?.filters?.proposedRent?.min ? `>=${query?.filters?.proposedRent?.min}` : undefined,
          query?.filters?.proposedRent?.max ? `<=${query?.filters?.proposedRent?.max}` : undefined,
        ],
        status: query.filters?.status ? query.filters.status.map((s) => `OR%${s}`) : undefined,
      },
      page: query.page,
      perPage: query.perPage,
      sorters: query.sorters,
    },
    { encode: false, arrayFormat: 'repeat' },
  );
  return axiosInstance.get(`${OffersApiPath.GET_RENTER_OFFERS}?${queryString}`);
};

export const getOffersByUnitId = (unitId?: string): Promise<OffersDto> => {
  return axiosInstance.get(`${OffersApiPath.GET_ESTATE_OFFERS_BY_UNIT}/${unitId}`);
};

export const getRenterOfferById = (id: string): Promise<OfferItemDto> => {
  return axiosInstance.get(`${OffersApiPath.GET_RENTER_OFFERS}/${id}`);
};

export const getOfferDetails = (id: string): Promise<OffersItemDto> => {
  return axiosInstance.get(`${OffersApiPath.GET_OFFERS}/${id}`);
};

export const createOffer = (payload: CreateOfferRequestDto): Promise<string> => {
  return axiosInstance.post(`${OffersApiPath.CREATE_OFFER}`, payload);
};

export const acceptOffer = (applicationId: string): Promise<boolean> => {
  return axiosInstance.put(`${OffersApiPath.ACCEPT_OFFER}/${applicationId}/accept`);
};

export const rejectOffer = ({ applicationId, reason }: { applicationId: string; reason: string }): Promise<boolean> => {
  return axiosInstance.put(`${OffersApiPath.REJECT_OFFER}/${applicationId}/reject`, { reason });
};

export const requestBackgroundCheck = (applicationId: string): Promise<boolean> => {
  return axiosInstance.put(`${OffersApiPath.MAIN_OFFER_PATH}/${applicationId}/backgroundCheck/request`);
};

export const getOfferPayment = (offerId: string): Promise<OfferPaymentIntentDto> => {
  return axiosInstance.post(`${OffersApiPath.OFFER_PAYMENT}/${offerId}`);
};

export const getPreOfferPayment = (offerId: string): Promise<OfferPaymentIntentDto> => {
  return axiosInstance.post(`${OffersApiPath.PRE_OFFER_PAYMENT}/${offerId}`);
};

export const getOfferFiles = (applicationId: string): Promise<OfferFileDto[]> => {
  return axiosInstance.get(`/irent/RentApplications/${applicationId}/files`);
};

export const uploadOfferFiles = async (applicationId: string, files: File[]): Promise<string[]> => {
  const filesIds: string[] = [];
  for (let file of files) {
    const formData = new FormData();
    formData.append('file', file);
    const fileId = await axiosInstance.post<void, string>(`/irent/RentApplications/${applicationId}/files`, formData);
    filesIds.push(fileId);
  }
  return filesIds;
};

export const getApplicationInfo = (
  applicationId: string,
): Promise<{
  leaseDocumentUrl: string;
}> => {
  return axiosInstance.get(`/irent/RentApplications/my/${applicationId}`);
};

export const uploadSignedOfferFiles = async (applicationId: string, files: File[]): Promise<string[]> => {
  const filesIds: string[] = [];
  for (let file of files) {
    const formData = new FormData();
    formData.append('file', file);
    const fileId = await axiosInstance.post<void, string>(`/irent/RentApplications/${applicationId}/sign`, formData);
    filesIds.push(fileId);
  }
  return filesIds;
};

export const cancelOffer = (applicationId: string): Promise<boolean> => {
  return axiosInstance.put(`/irent/RentApplications/${applicationId}/cancel`);
};

export const getMyOffersByEstateId = (estateId: string): Promise<UserEstateRentApplication[]> => {
  return axiosInstance.get(`/irent/RentApplications/estate/my/${estateId}`);
};

export const paymentInProgressOffer = (applicationId: string): Promise<boolean> => {
  return axiosInstance.put(buildOfferPendingInProgressPaymentUrl(applicationId));
};

export const updateOffer = (offerId: string, payload: UpdateOfferRequestDto): Promise<string> => {
  return axiosInstance.post(`${OffersApiPath.CREATE_OFFER}/${offerId}`, payload);
};

export const getRenterOfferFiles = (offerId: string): Promise<OfferFileDto[]> => {
  return axiosInstance.get(`${OffersApiPath.MAIN_OFFER_PATH}/${offerId}/files/renter`);
};

export const deleteRenterOfferFile = (query: OfferFileDeleteRequest): Promise<void> => {
  return axiosInstance.delete(`${OffersApiPath.MAIN_OFFER_PATH}/${query?.offerId}/files/${query?.fileId}`);
};
